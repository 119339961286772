*, ::after, ::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $iit_font_body;
}
::selection{
    background: $iit_color_secondary;
    color: $iit_color_primary;
}
a{
    color: inherit;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        color: inherit;
    }
}
body{
    background: $iit_color_background;
}
ul{
    list-style: none;
}
img{
    pointer-events: none;
}