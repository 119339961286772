.iit-ui-error{
    position: relative;
    width: 100%;
    height: calc(100vh - 100px);
    margin-top: 50px;
    // background: chartreuse;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    .iit-ui-error__text{
        position: relative;
        width: 100%;
        text-align: center;
        padding: 10px;
        overflow: hidden;
        p{
            font-size: 22px;
        }
        h1{
            font-size: 46px;
            text-transform: uppercase;
            font-family: $iit_font_variable;
            font-weight: 900;
        }
    }
}
.iit-ui-error__header{
    position: fixed;
    top: 10px;
    left: 50%;
    width: 50px;
    text-align: center;
    height: 50px;
    padding: 5px;
    opacity: 0.7;
    a{
        position: relative;
        width: 100%;
        height: 100%;
    }
    svg{
        height: 100%;
        width: auto;
        margin-left: -10px;
    }
}
.iit-ui-error__btn{
    position: relative;
   
    a{
        position: relative;
        margin: auto;
        margin-top: 20px;
        max-width: 280px;
        min-height: 50px;
        padding: 8px 16px;
        background: $iit_color_secondary;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px;
        color: $iit_color_primary;
        transition: 0.25s ease;
        overflow: hidden;
        span{
            transition: 0.25s ease;
        }
        &::after{
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            background: $iit_color_secondaryDark;
            border-radius: 2px;
            // transform: translateY(-100%);
            transition: 0.25s ease;
        }
        &:hover{
            span{
                margin-top: -5px;
            }
            &::after{
                bottom: 0;
            }
        }
    }
    
}