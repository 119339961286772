.iit-ui-loader{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .iit-ui-loader__text{
        position: relative;
        width: 100%;
        padding: 5px;
        overflow: hidden;

        p{
            font-size: 16px;
        }
        h1{
            font-size: 54px;
            text-transform: uppercase;
            font-weight: 900;
            font-family: $iit_font_variable;
            color: $iit_color_secondary;
        }
    }
}
.iit-ui-home__banner{
    position: relative;
    width: 100%;
    height: 100vh;
    .iit-ui-home-banner__container{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        @include media('<=991px'){
            flex-direction: column-reverse;
        }
        .iit-ui-home-banner__text{
            position: relative;
            width: 45%;
            height: 100%;
            padding: 15px;
            @include media('<=991px'){
                width: 100%;
                height: 40vh;
            }
            .iit-ui-home-banner__subtext{
                position: relative;
                margin-top: 5vh;
                margin-left: 15px;
                width: auto;
                @include media('<=991px'){
                    display: none;
                }
                .iit-ui-home-banner-subtext__text{
                    position: absolute;
                    width: auto;
                    background: $iit_color_background;

                    span{
                        opacity: 0.25;
                        font-size: 84px;
                        font-family: $iit_font_variable;
                        font-weight: 900;
                        text-transform: uppercase;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: $iit_color_black;
                        -webkit-text-fill-color: transparent;
                        text-transform: uppercase;
                        pointer-events: none;
                        @include media('<=1280px'){
                            font-size: 64px;
                        }
                    }
                    &:nth-child(1){
                        top: 0;
                    }
                    &:nth-child(2){
                        top: 40px;
                    }
                    &:nth-child(3){
                        top: 80px;
                    }
                }
            }
            .iit-ui-home-banner-text__container{
                position: absolute;
                width: 100%;
                top: 50%;
                left: 2vw;
                transform: translateY(-50%);
                @include media('<=991px'){
                   top: 0;
                   transform: translateY(0);
                   left: 0px;
                   padding: 15px;
                }
                .iit-ui-home-banner-text__subtitle{
                    position: relative;
                    p{
                        font-size: 22px;
                        @include media('<=991px'){
                            font-size: 16px;
                        }
                    }
                }
                .iit-ui-home-banner-text__title{
                    position: relative;
                    width: 100%;
                    padding-right: 40px;
                    margin-top: 10px;
                    @include media('<=991px'){
                        padding-right: 0;
                    }
                    // padding: 15px;
                    h1{
                        font-size: 2.5vw;
                        font-family: $iit_font_variable;
                        text-transform: uppercase;
                        font-weight: 900;
                        @include media('<=991px'){
                            font-size: 24px;
                        }
                    }
                }
            }
            .iit-ui-vertical-bar{
                position: absolute;
                height: 100px;
                width: 2px;
                background: $iit_color_black;
                // left: 15px;
                bottom: 50px;
                transform-origin: top;
                @include media('<=991px'){
                    display: none;
                }
            }
            .iit-ui-scrollDown{
                position: absolute;
                width: 100%;
                bottom: 10px;
                // padding-left: 15px;
                height: 40px;
                font-size: 18px;
                display: flex;
                align-items: center;
                @include media('<=991px'){
                    font-size: 14px;
                    width: auto;
                }
            }
        }
        .iit-ui-home-banner__images{
            position: relative;
            width: 55%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            @include media('<=991px'){
                width: 100%;
                height: 60vh;
            }
            .iit-ui-home-banner-images__container{
                position: relative;
                width: 100%;
                height: calc(100% - 60px);
                padding: 5px;
                display: flex;
                justify-content: space-between;
                .iit-ui-home-banner-image{
                    position: relative;
                    width: calc(100% / 3 - 5px);
                    height: 100%;
                    overflow: hidden;
                    border-radius: 10px;
                    transform-origin: bottom;
                    transform: scaleY(1);
                    img{
                        position: relative;
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover;
                        object-position: center;
                        transform-origin: center;
                        transform: scale(1);
                        transition: 0.5s ease;
                    }
                    // &:nth-child(2){
                    //     img{
                    //         object-position: -600px center;
                    //         @include media('<=991px'){
                    //             object-position: -300px center;
                    //         }
                    //         @include media('<=501px'){
                    //             object-position: -400px center;
                    //         }
                    //         @include media('<=401px'){
                    //             object-position: -300px center;
                    //         }
                    //     }
                    // }
                    &:hover{
                        img{
                            transform: scale(1.03);
                        }
                    }
                    .iit-ui-blank{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform-origin: bottom;
                        background: $iit_color_background;
                    }
                    .iit-ui-home-banner-image__text{
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        height: 100%;
                        width: 100%;
                        background: linear-gradient(transparent,transparent, $iit_color_black);
                        padding: 10px;
                        p{
                            font-size: 8vw;
                            // color: $iit_color_white;
                            font-weight: 900;
                            font-family: $iit_font_variable;
                            line-height: 100px;
                            text-align: center;
                            -webkit-text-stroke-width: 2px;
                            -webkit-text-stroke-color: $iit_color_white;
                            -webkit-text-fill-color: transparent;
                            opacity: 0.2;
                        }
                    }
                }
            }
        }
    }
}
.iit-ui-home__about{
    position: relative;
    width: 100%;
    height: 100vh;
    @include media('<=991px'){
        height: auto;
        // display: none;
    }
    .iit-ui-home-about__container{
        position: relative;
        // width: 100%;
        max-width: 1500px;
        height: 100%;
        margin: auto;
        @include media('<=991px'){
            height: auto;
        }
        .iit-ui-home-about__subtext{
            position: absolute;
            top: 5vh;
            right: 15px;
            width: calc(100% - 30px);
            text-align: right;
            @include media('<=991px'){
                display: none;
            }
            .iit-ui-home-about-subtext__text{
                position: absolute;
                background: $iit_color_background;
                width: 100%;
                span{
                    opacity: 0.25;
                    font-size: 84px;
                    font-family: $iit_font_variable;
                    font-weight: 900;
                    text-transform: uppercase;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: $iit_color_black;
                    -webkit-text-fill-color: transparent;
                    text-transform: uppercase;
                    pointer-events: none;
                    @include media('<=1280px'){
                        font-size: 64px;
                    }
                }
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 40px;
                }
                &:nth-child(3){
                    top: 80px;
                }
            }
        }
        .iit-ui-home-about__content{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media('<=991px'){
                padding: 15px;
                height: auto;
                flex-direction: column;
                align-items: flex-start;
            }
            .iit-ui-home-about-content__image{
                position: relative;
                width: 35%;
                height: 69vh;
                background: #c2c2c2;
                // margin-left: 10vw;
                // margin-top: 23vh;
                border-radius: 20px;
                overflow: hidden;
                @include media('<=991px'){
                    width: 100%;
                }
                img{
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: center;
                    transform-origin: center;
                    transform: scale(1);
                    transition: 0.5s ease;
                }
                &:hover{
                    img{
                        transform: scale(1.03);
                    }
                }
            }
            .iit-ui-home-about-content__text{
                position: relative;
                width: 50%;
                padding: 80px;
                @include media('<=991px'){
                    width: 100%;
                    margin-top: 20px;
                    padding: 0;
                }
                .iit-ui-home-about-content__title{
                    position: relative;
                    h1{
                        font-size: 3vw;
                        text-transform: uppercase;
                        font-family: $iit_font_variable;
                        font-weight: 900;
                        @include media('<=991px'){
                            font-size: 24px;
                        }
                    }
                }
                .iit-ui-home-about-content__paragraph{
                    position: relative;
                    margin-top: 20px;
                    width: 70%;
                    @include media('<=991px'){
                        width: 100%;
                    }
                }
            }
        }
    }
}
.iit-ui-home__objectif{
    position: relative;
    width: 100%;
    height: 100vh;
    @include media('<=991px'){
        height: auto;
        margin-top: 20px;
        // display: none;
    }
    .iit-ui-home-ojectif__container{
        position: relative;  
        max-width: 1500px;
        height: 100%;
        margin: auto;
        @include media('<=991px'){
            height: auto;
        }
        .iit-ui-home-objectif__subtext{
            position: absolute;
            top: 5vh;
            left: 15px;
            width: calc(100% - 30px);
            // height: 30vh;
            // text-align: right;
            @include media('<=991px'){
                display: none;
            }
            .iit-ui-home-objectif-subtext__text{
                position: absolute;
                background: $iit_color_background;
                width: 100%;
                span{
                    opacity: 0.25;
                    font-size: 84px;
                    font-family: $iit_font_variable;
                    font-weight: 900;
                    text-transform: uppercase;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: $iit_color_black;
                    -webkit-text-fill-color: transparent;
                    text-transform: uppercase;
                    pointer-events: none;
                    @include media('<=1280px'){
                        font-size: 64px;
                    }
                }
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 40px;
                }
                &:nth-child(3){
                    top: 80px;
                }
            }
        }
        .iit-ui-home-objectif__content{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            @include media('<=991px'){
                height: auto;
                flex-direction: column;
                padding: 15px;
            }
            .iit-ui-home-objectif-content__image{
                position: relative;
                width: 35%;
                height: 69vh;
                background: #c2c2c2;
                // margin-top: 23vh;
                border-radius: 20px;
                overflow: hidden;
                @include media('<=991px'){
                    width: 100%;
                }
                img{
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: center;
                    transform-origin: center;
                    transform: scale(1);
                    transition: 0.5s ease;
                }
                &:hover{
                    img{
                        transform: scale(1.03);
                    }
                }
            }
            .iit-ui-home-objectif-content__text{
                position: relative;
                width: 50%;
                padding: 80px;
                @include media('<=991px'){
                    width: 100%;
                    padding: 0;
                    margin-top: 20px;
                }
                .iit-ui-home-objectif-content__title{
                    position: relative;
                    h1{
                        font-size: 3vw;
                        text-transform: uppercase;
                        font-family: $iit_font_variable;
                        font-weight: 900;
                        @include media('<=991px'){
                            font-size: 24px;
                        }
                    }
                }
                .iit-ui-home-objectif-content__paragraph{
                    position: relative;
                    margin-top: 20px;
                    width: 75%;

                    @include media('<=991px'){
                        width: 100%;
                    }
                }
            }
        }
    }
}
.iit-ui-home__services{
    position: relative;
    width: 100%;
    height: 100vh;
    @include media('<=991px'){
        height: auto;
        margin-top: 20px;
        // display: none;
    }
    .iit-ui-home-services__container{
        position: relative;
        max-width: 1500px;
        height: 100%;
        margin: auto;
        @include media('<=991px'){
            height: auto;
        }
        .iit-ui-home-services__subtext{
            position: absolute;
            @include media('<=991px'){
                display: none;
            }
            &.top{
                top: 2vh;
                left: 15px;
            }
            width: calc(100% - 30px);
            // height: 30vh;
            // text-align: right;
            &.bottom{
                bottom: 2vh;
                left: 15px;
                height: 20vh;
                text-align: right;
            }
            .iit-ui-home-services-subtext__text{
                position: absolute;
                background: $iit_color_background;
                width: 100%;
                span{
                    opacity: 0.25;
                    font-size: 84px;
                    font-family: $iit_font_variable;
                    font-weight: 900;
                    text-transform: uppercase;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: $iit_color_black;
                    -webkit-text-fill-color: transparent;
                    text-transform: uppercase;
                    pointer-events: none;
                    @include media('<=1280px'){
                        font-size: 64px;
                    }
                }
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 40px;
                }
                &:nth-child(3){
                    top: 80px;
                }
            }
        }
        .iit-ui-home-services__grid-container{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media('<=991px'){
                padding: 15px;
                height: auto;
            }
            .iit-ui-home-services__grid{
                position: relative;
                width: 912px;
                height: auto;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 10px;
                row-gap: 10px;
                @include media('<=991px'){
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    &::after{
                        content: 'Nos services';
                        position: absolute;
                        top: -30px;
                        left: 0;
                        font-size: 18px;
                        font-family: $iit_font_variable;
                        font-weight: 900;
                    }
                }
                .iit-ui-home-services__grid-box{
                    position: relative;
                    width: 100%;
                    height: 20vh;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 15px;
                    border: 2px solid rgba($color: $iit_color_black, $alpha: .1);
                    border-radius: 10px;
                   
                    @include media('<=991px'){
                        width: calc(50% - 10px);
                        height: 100px;
                        // margin-bottom: 20px;
                    }
                    @include media('<=400px'){
                        width: 100%;
                        // margin-bottom: 20px;
                    }
                    svg{
                        width: 60px;
                        @include media('<=991px'){
                           width: 40px;
                        }
                    }
                    .iit-ui-home-services-grid__text{
                        position: relative;
                        width: calc(100% - 100px);
                        @include media('<=991px'){
                            width: calc(100% - 20px);
                         }
                        // margin-left: 20px;
                        text-align: center;
                        strong{
                            font-size: 22px;
                            @include media('<=991px'){
                                font-size: 16px;
                            }
                        }
                    }
                    // background: chartreuse;
                }
            }
        }
    }
}
.iit-ui-home__contact{
    position: relative;
    width: 100%;
    height: 100vh;
    @include media('<=991px'){
        height: auto;
        margin-top: 20px;
        // display: none;
    }
    .iit-ui-home-contact__container{
        position: relative;
        max-width: 1500px;
        height: 100%;
        margin: auto;
        @include media('<=991px'){
            height: auto;
        }
        .iit-ui-home-contact__subtext{
            position: absolute;
            bottom: 5vh;
            right: 15px;
            width: calc(100% - 30px);
            text-align: right;
            height: 30vh;
            @include media('<=991px'){
                display: none;
            }
            .iit-ui-home-contact-subtext__text{
                position: absolute;
                background: $iit_color_background;
                width: 100%;
                span{
                    opacity: 0.25;
                    font-size: 96px;
                    font-family: $iit_font_variable;
                    font-weight: 900;
                    text-transform: uppercase;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: $iit_color_black;
                    -webkit-text-fill-color: transparent;
                    text-transform: uppercase;
                    pointer-events: none;
                }
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 40px;
                }
                &:nth-child(3){
                    top: 80px;
                }
            }
        }
        .iit-ui-home-contact__content{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media('<=991px'){
                flex-direction: column;
                padding: 15px;
            }
            .iit-ui-home-contact-content__image{
                position: relative;
                width: 35%;
                height: 69vh;
                background: #c2c2c2;
                // margin-left: 10vw;
                // margin-top: 23vh;
                border-radius: 20px;
                overflow: hidden;
                @include media('<=991px'){
                    width: 100%;
                    margin-bottom: 20px;
                }
                img{
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: center;
                    transform-origin: center;
                    transform: scale(1);
                    transition: 0.5s ease;
                }
                &:hover{
                    img{
                        transform: scale(1.03);
                    }
                }
            }
            .iit-ui-home-contact-content__text{
                position: relative;
                width: 50%;
                padding: 80px;
                @include media('<=991px'){
                    width: 100%;
                    padding: 0;
                }
                .iit-ui-home-contact-content__title{
                    position: relative;
                    h1{
                        font-size: 3vw;
                        text-transform: uppercase;
                        font-family: $iit_font_variable;
                        font-weight: 900;
                        @include media('<=991px'){
                            font-size: 24px;
                        }
                    }
                }
                .iit-ui-home-contact-content__paragraph{
                    position: relative;
                    margin-top: 20px;
                    width: 70%;
                    @include media('<=991px'){
                        width: 100%;
                    }
                }
            }
        }
    }
}
.iit-ui-home__partner{
    position: relative;
    width: 100%;
    margin-top: 50px;
    padding: 40px;
    @include media('<=991px'){
        padding: 15px;
        // display: none;
    }
    .iit-ui-home-partner__container{
        position: relative;
        max-width: 1300px;
        margin: auto;
        text-align: center;
        @include media('<=991px'){
            width: 100%;
        }
        .iit-ui-home-partner__grid{
            position: relative;
            width: 100%;
            margin-top: 30px;
            display: flex;
            // grid-template-columns: repeat(4, 1fr);
            // column-gap: 10px;
            // row-gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            @include media('<=991px'){
                // justify-content: space-between;
                
            }
            .iit-ui-home-partner__gridBox{
                position: relative;
                // width: calc(100% / 4 - 10px);
                width: auto;
                height: 90px;
                @include media('<=991px'){
                    height: 70px;
                    margin-right: 10px;
                }
                // height: 200px;
                margin-right: 80px;
                margin-bottom: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 30px;
                // background: chartreuse;
                img{
                    position: relative;
                    width: auto;
                    height: 100% !important;
                    filter: grayscale(1);
                   
                    // margin: auto;
                }
            }
        }
    }
}