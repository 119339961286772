.iit-ui-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 999;
    background: $iit_color_background;
    .iit-ui-header__container{
        position: relative;
        width: calc(100% - 50px);
        height: 100%;
        margin: auto;
        @include media('<=991px'){
            width: calc(100% - 30px);
        }
        .iit-ui-header__logo{
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 100%;
            display: flex;
            align-items: center;
            @include media('<=240px'){
                left: 50%;
                transform: translateX(-50%);
            }
            svg{
                margin-left: -20px;
                height: 30px;
                path{
                    fill: $iit_color_primary;
                }
            }

        }
        .iit-ui-header__navigation{
            position: absolute;
            top: 0;
            left: 50%;
            width: auto;
            height: 100%;
            transform: translateX(-50%);
            @include media('<=991px'){
                display: none;
            }
            .iit-ui-header-navigation__list{
                position: relative;
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                .iit-ui-header-navigation__item{
                    position: relative;
                    width: 150px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    a{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding: 5px;
                        transition: 0.5s ease;
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: 13px;
                            left: 50%;
                            transform: scaleX(0) translateX(-50%);
                            width: 20px;
                            height: 3px;
                            background: $iit_color_secondary;
                            transform-origin: center;
                            transition: 0.5s ease;
                        }
                        &:hover{
                            color: $iit_color_primary;
                            &::after{
                                transform: scaleX(1) translateX(-50%);
                            }
                        }
                    }
                }
            }
        }
        .iit-ui-header__action{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            @include media('<=240px'){
                display: none;
            }
            a{
                position: relative;
                width: auto;
                padding: 2px 0px;
                // height: 45px;
                // background: $iit_color_secondary;
                // color: $iit_color_secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                transition: 0.5s ease;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: $iit_color_primary;
                    bottom: 0;
                    left: 0;
                    transform-origin: left;
                    transform: scaleX(0);
                    transition: 0.5s ease;
                }
                &:hover{
                    color: $iit_color_secondary;
                    &::after{
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
.iit-ui-footer{
    position: relative;
    margin-top: 10px;
    width: 100%;
    padding: 10px 0;
    .iit-ui-footer__container{
        position: relative;
        width: calc(100% - 50px);
        @include media('<=991px'){
            width: calc(100% - 30px);
        }
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include media('<=991px'){
            width: 100%;
            padding: 15px;
            // height: auto;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        a{
            margin-bottom: 10px;
            transition: 0.25s ease;
            &:hover{
                color: $iit_color_primary;
            }
        }
    }
}
.iit-ui-scrollTop{
    position: fixed;
    bottom: 30px;
    right: 30px;
    @include media('<=991px'){
        bottom: 30px;
        right: 20px;
    }
    z-index: 999;
    background: $iit_color_secondary;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    svg{
        width: 30px;
        height: 30px;
        transform: rotate(-90deg);
    }
    &:hover{
        svg{
            animation: moveAnimation 0.5s ease-in-out;
        }
    }
    #svg_color{
        fill: $iit_color_primary;

    }
}
@keyframes moveAnimation {
    0%{
        transform: translateY(100%) rotate(-90deg);
    }
    50%{
        transform: translateY(-100%) rotate(-90deg);
    }
    100%{
        transform: translateY(0) rotate(-90deg);
    }
}